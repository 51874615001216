import sanitizeHtml from 'sanitize-html';
import { format, minutesToHours, differenceInMinutes } from 'date-fns';
import { fleetBrandOptions, fleetClassificationOptions, utcOffset } from './constant';

export function capitalize(text) {
  const val = text.split('_').join(' ');
  return val.charAt(0).toUpperCase() + val.slice(1);
};

export function formatDateTime(dateToFormat, showTimezone = false) {
  if (new Date(dateToFormat)) {
    if (showTimezone) {
      const timezoneOffset = new Date().getTimezoneOffset();
      const symbol = (timezoneOffset > 0) ? '-' : '+'
      const hour = ('0' + Math.abs(minutesToHours(timezoneOffset))).slice(-2)
      const minutes = ('0' + Math.abs(timezoneOffset % 60)).slice(-2)

      return `${format(new Date(dateToFormat), "dd-MM-yyyy HH:mm:ss")} (UTC ${symbol}${hour}:${minutes})`
    }
    return format(new Date(dateToFormat), "dd-MM-yyyy HH:mm:ss")
  }
  return '';
};

export function formatDuration(value) {
  if(value != null) {
    const hours = Math.floor(value)
    const minutes = Math.ceil((value * 60) % 60)
  
    const hourString = hours ? `${hours} h` : ''
    const minuteString = minutes ? ` ${minutes} m` : ''
  
    return hourString + minuteString
  } return '-'
};

export const highlightString = (str, keyword) => {
  if (!str) return null;
  const sanitizedInput = sanitizeHtml(str);
  const rex = new RegExp(keyword, 'gi');
  return sanitizedInput.replace(rex, '<span class="text-danger">$&</span>');
};

export const isEmptyObject = (obj) => {
  if (typeof obj !== 'object') return false;
  return Object.keys(obj).length === 0;
};

export const getCenterLocation = (start, end) => {
  // Extract latitude and longitude from each point
  const lat1 = start.latitude;
  const lon1 = start.longitude;
  const lat2 = end.latitude;
  const lon2 = end.longitude;

  // Calculate the average latitude and longitude
  const centerLat = (lat1 + lat2) / 2;
  const centerLon = (lon1 + lon2) / 2;

  // Create a new point representing the center
  const centerPoint = {
    latitude: centerLat,
    longitude: centerLon,
  };

  return centerPoint;
};

export const getSiteMarkerIcon = (category) => {
  switch (category) {
    case 'head_office':
      return require('@/assets/images/googlemaps/site/head.svg')
    case 'district_office':
      return require('@/assets/images/googlemaps/site/district.svg')
    case 'external':
      return require('@/assets/images/googlemaps/site/external.svg')
    case 'supplier':
      return require('@/assets/images/googlemaps/site/supplier.svg')
    case 'store':
      return require('@/assets/images/googlemaps/site/store.svg')
    case 'production_building':
      return require('@/assets/images/googlemaps/site/production.svg')
    case 'warehouse_fix_goods':
      return require('@/assets/images/googlemaps/site/fix.svg')
    case 'warehouse_raw_material':
      return require('@/assets/images/googlemaps/site/raw.svg')
    case 'parking_lot':
      return require('@/assets/images/googlemaps/site/parking.svg')
    default:
      return require('@/assets/images/googlemaps/site/default.svg')
  }
};

export const getSiteColor = (category) => {
  switch (category) {
    case 'head_office':
      return '#FF0000'
    case 'district_office':
      return '#FFC700'
    case 'external':
      return '#0038FF'
    case 'supplier':
      return '#00F0FF'
    case 'store':
      return '#05FF00'
    case 'parking_lot':
      return '#C21F81'
    case 'production_building':
      return '#FF00B8'
    case 'warehouse_fix_goods':
      return '#BD00FF'
    case 'warehouse_raw_material':
      return '#402BB7'
    default:
      return '#000000'
  }
};

export const getFleetBrandIcon = (brand) => {
  const selectedBrand = fleetBrandOptions.find((item) => item.value == brand)
  return selectedBrand ? selectedBrand.icon : null
};

export const getFleetClassificationLabel = (classification) => {
  const selectedClassification = fleetClassificationOptions.find((item) => item.value == classification)
  return selectedClassification ? selectedClassification.label : null
};

export const parsePayloadKey = (payloadKey) => {
  const sanitizedValue = payloadKey.toString().toLowerCase();
  
  switch(sanitizedValue) {
    case 'altitude':
      return { name: 'Altitude', unit: 'm' }
    case 'counter':
      return { name: 'Counter', unit: 'times' }
    case 'created_at':
      return { name: 'Datetime', unit: utcOffset }
    case 'latitude':
      return { name: 'Latitude', unit: '°'}
    case 'longitude':
      return { name: 'Longitude', unit: '°' }
    case 'average_fuel_economy': 
      return { name: 'Avg fuel economy', unit: 'km/L' }
    case 'brake_primary_pressure': 
      return { name: 'Brake primary pressure', unit: 'bar' }
    case 'brake_secondary_pressure': 
      return { name: 'Brake secondary pressure', unit: 'bar' }
    case 'coolant_level': 
      return { name: 'Coolant level', unit: '%' }
    case 'elevation': 
      return { name: 'Elevation', unit: '°' }
    case 'elevation_info': 
      return { name: 'Elevation info', unit: '' }
    case 'engine_amber_warning_lamp_status': 
      return { name: 'Engine amber warning lamp status', unit: '' }
    case 'engine_coolant_temperature': 
      return { name: 'Engine coolant temperature', unit: '°C' }
    case 'engine_oil_pressure': 
      return { name: 'Engine oil pressure', unit: 'bar' }
    case 'engine_red_stop_lamp_status': 
      return { name: 'Engine red stop lamp status', unit: '' }
    case 'engine_speed': 
      return { name: 'Engine speed', unit: 'rpm' }
    case 'engine_total_fuel_used': 
      return { name: 'Engine total fuel used', unit: 'L' }
    case 'engine_total_hour_operation': 
      return { name: 'Engine total hour operation', unit: 'h' }
    case 'fuel_level': 
      return { name: 'Fuel level', unit: '%' }
    case 'gps_vehicle_speed': 
      return { name: 'GPS vehicle speed', unit: 'km/h' }
    case 'heading': 
      return { name: 'Heading', unit: '°' }
    case 'heading_cardinal': 
      return { name: 'Heading cardinal', unit: '' }
    case 'ignition': 
      return { name: 'Ignition', unit: '' }
    case 'malfunction_indicator_lamp_status': 
      return { name: 'Malfunction indicator lamp status', unit: '' }
    case 'parking_brake': 
      return { name: 'Parking brake', unit: '' }
    case 'protect_lamp_status': 
      return { name: 'Protect lamp status', unit: '' }
    case 'rssi': 
      return { name: 'RSSI', unit: 'dBm' }
    case 'snr': 
      return { name: 'SNR', unit: 'dB' }
    case 'total_idle_fuel_used': 
      return { name: 'Total idle fuel used', unit: 'L' }
    case 'total_idle_hours': 
      return { name: 'Total idle hours', unit: '' }
    case 'total_vehicle_distance': 
      return { name: 'Total vehicle distance', unit: 'km' }
    case 'wheel_based_vehicle_speed': 
      return { name: 'Wheel based vehicle speed', unit: 'km/h' }
    default: {
      return { name: capitalize(sanitizedValue), unit: '' }
    }
  }
}