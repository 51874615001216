import { minutesToHours } from 'date-fns';

export const roleOptions = [
  {value: 'planner', label: 'Planner'},
  {value: 'maintenance', label: 'Maintainer'},
  {value: 'manager', label: 'Manager'},
];

export const siteTypeOptions = [
  { value: 'head_office', label: 'Head Office', icon: require('@/assets/images/googlemaps/site/head.svg') },
  { value: 'district_office', label: 'District Office', icon: require('@/assets/images/googlemaps/site/district.svg') },
  { value: 'external', label: 'External', icon: require('@/assets/images/googlemaps/site/external.svg') },
  { value: 'supplier', label: 'Supplier', icon: require('@/assets/images/googlemaps/site/supplier.svg') },
  { value: 'store', label: 'Store', icon: require('@/assets/images/googlemaps/site/store.svg') },
  { value: 'production_building', label: 'Production Building', icon: require('@/assets/images/googlemaps/site/production.svg') },
  { value: 'warehouse_fix_goods', label: 'Warehouse Fix Goods', icon: require('@/assets/images/googlemaps/site/fix.svg') },
  { value: 'warehouse_raw_material', label: 'Warehouse Raw Material', icon: require('@/assets/images/googlemaps/site/raw.svg') },
  { value: 'parking_lot', label: 'Parking Lot', icon: require('@/assets/images/googlemaps/site/parking.svg') },
];

export const sourceOptions = [
  {value: 'GEO_RESOLVER_GNSS', label: 'Geo Resolver GNSS'},
  {value: 'GEO_RESOLVER_WIFI', label: 'Geo Resolver Wifi'},
];

// Fleet
export const fleetBrandOptions = [
  { value: 'Daihatsu', label: 'Daihatsu', icon: require('@/assets/images/logo/brand-daihatsu.png') },
  { value: 'Hino', label: 'Hino', icon: require('@/assets/images/logo/brand-hino.png') },
  { value: 'Isuzu', label: 'Isuzu', icon: require('@/assets/images/logo/brand-isuzu.png') },
  { value: 'Lexus', label: 'Lexus', icon: require('@/assets/images/logo/brand-lexus.png') },
  { value: 'Mercedes-Benz', label: 'Mercedes-Benz', icon: require('@/assets/images/logo/brand-mercedes-benz.png') },
  { value: 'Mitsubishi', label: 'Mitsubishi', icon: require('@/assets/images/logo/brand-mitsubishi.png') },
  { value: 'Nissan', label: 'Nissan', icon: require('@/assets/images/logo/brand-nissan.png') },
  { value: 'Scania', label: 'Scania', icon: require('@/assets/images/logo/brand-scania.png') },
  { value: 'Suzuki', label: 'Suzuki', icon: require('@/assets/images/logo/brand-suzuki.png') },
  { value: 'Toyota', label: 'Toyota', icon: require('@/assets/images/logo/brand-toyota.png') },
  { value: 'UD Trucks', label: 'UD Trucks', icon: require('@/assets/images/logo/brand-ud.png') },
  { value: 'Volvo', label: 'Volvo', icon: require('@/assets/images/logo/brand-volvo.png') },
];

export const fleetCargoTypeOptions = [
  { value: 'blind van', label: 'Blind Van' },
  { value: 'box', label: 'Box' },
  { value: 'carrier', label: 'Carrier' },
  { value: 'dump', label: 'Dump' },
  { value: 'flat deck', label: 'Flat Deck' },
  { value: 'open back', label: 'Open Back' },
  { value: 'pickup bak', label: 'Pickup Bak' },
  { value: 'pickup box', label: 'Pickup Box' },
  { value: 'towing', label: 'Towing' },
  { value: 'wing box', label: 'Wing Box' },
];

export const fleetTypeOptions = [
  { value: 'Non Gandeng', label: 'Truk Non Gandeng' },
  { value: 'Gandeng', label: 'Truk Gandeng' },
  { value: 'Minibus', label: 'Mini Bus' },
  { value: 'Minivan', label: 'Mini Van' },
];

export const fleetIgnitionOptions = [
  { value: '', label: 'All' },
  { value: '1', label: 'Ignition On', icon: require('@/assets/images/icons/ignition-on.svg')},
  { value: '0', label: 'Ignition Off', icon: require('@/assets/images/icons/ignition-off.svg')},
];

export const fleetActivityOptions = [
  { value: '', label: 'All' },
  { value: 'departure', label: 'Departure'},
  { value: 'arrival', label: 'Arrival'},
];

export const fleetCanBusOptions = [
  { value: '1', label: 'Available'},
  { value: '0', label: 'Unavailable'},
];

export const fleetClassificationOptions = [
  {value: 'ldv', label: 'LDV - <3.5T'},
  {value: 'mdv', label: 'MDV - <12T'},
  {value: 'hdv', label: 'HDV - >12T'},
];

export const fleetFuelTypeOptions = [
  {value: 'diesel', label: 'Diesel'},
  {value: 'biodiesel', label: 'Biodiesel'},
  {value: 'motor gasoline', label: 'Motor Gasoline'},
];

// Organization
export const timezoneOptions = [
  { value: 'Asia/Jakarta', label: '(GMT+07:00) Western Indonesia Time - Jakarta' },
  { value: 'Asia/Makassar', label: '(GMT+08:00) Central Indonesia Time - Makassar' },
  { value: 'Asia/Jayapura', label: '(GMT+09:00) Eastern Indonesia Time - Jayapura' },
];

// utcOffset
const timezoneOffset = new Date().getTimezoneOffset();
const symbol = (timezoneOffset > 0) ? '-' : '+'
const hour = ('0' + Math.abs(minutesToHours(timezoneOffset))).slice(-2)
const minutes = ('0' + Math.abs(timezoneOffset % 60)).slice(-2)

export const utcOffset = `(UTC ${symbol}${hour}:${minutes})`

// Payload order
export const payloadOrder = [
  "created_at",
  "latitude",
  "longitude",
  "altitude",
  "counter",
  "accuracy",
  "satellite_number",
  "source",
  "rssi",
  "snr",
  "heading",
  "heading_cardinal",
  "elevation",
  "elevation_info",
  "ignition",
  "parking_brake",
  "wheel_based_vehicle_speed",
  "gps_vehicle_speed",
  "engine_speed",
  "total_vehicle_distance",
  "fuel_level",
  "average_fuel_economy",
  "engine_total_fuel_used",
  "total_idle_fuel_used",
  "engine_total_hour_operation",
  "total_idle_hours",
  "engine_coolant_temperature",
  "coolant_level",
  "brake_primary_pressure",
  "brake_secondary_pressure",
  "engine_oil_pressure",
  "malfunction_indicator_lamp_status",
  "engine_amber_warning_lamp_status",
  "engine_red_stop_lamp_status",
  "protect_lamp_status"
];